import { AvaNetwork } from "@/js/AvaNetwork";

export const MainnetConfig = new AvaNetwork(
  "Mainnet",
  "https://dijetsnet.uksouth.cloudapp.azure.com:443",
  1,
  "https://djtexplorer.uksouth.cloudapp.azure.com/",
  "https://explorer.dijets.io",
  true
);

export const TestnetConfig = new AvaNetwork(
  "Lothar",
  "https://testnet.northeurope.cloudapp.azure.com:443",
  5,
  "https://lothar.westeurope.cloudapp.azure.com/",
  "https://pulse.dijets.io",
  true
);
